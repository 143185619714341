import * as React from 'react';

import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import constants from '../constants';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import ContactButton from '../components/ContactButton';

const PricingPage = () => {

  const { t } = useTranslation();

  return (
    <Layout
      currentMenuItem='pricing'
    >
      <Seo
        title={'u1Dent+ Preise'}
        description={t('PremiumDescription')}
      />
      <div className="md:max-w-2xl mx-auto">
        <div className='flex flex-col md:flex-row'>
          <div className='relative flex flex-col md:w-1/2 pt-4 mb-1 md:my-0 md:mx-1 bg-grey-light dark:bg-grey-dark rounded-xl shadow-md overflow-hidden'>
            <div className='text-2xl text-center text-blue-light dark:text-blue-dark font-bold'>
              Individual-Abonnement
            </div>
            <div className='justify-center pb-2 px-4 text-center text-base text-text-light dark:text-text-dark'>
              Geeignet für einzelne Personen. Premium gilt für Käufer.
            </div>
            <div className='flex flex-row justify-around'>
              <div className='w-full items-center justify-center p-1 rounded-md'>
                <div className='flex flex-row px-2 justify-center'>
                  <div className='flex w-1/2 p-1 px-2 m-1 justify-center text-lg font-semibold text-center text-blue-light dark:text-blue-dark border-2 border-blue-light dark:border-blue-dark rounded-lg'>
                    monatlich
                  </div>
                  <div className='flex w-1/2 p-1 px-2 m-1 justify-center text-lg font-semibold text-center text-blue-light dark:text-blue-dark border-2 border-blue-light dark:border-blue-dark rounded-lg'>
                    jährlich
                  </div>
                </div>
                <div className='flex flex-row px-2'>
                  <ul className='list-none m-auto text-lg text-left text-text-light dark:text-text-dark'>
                    <li>
                      <FontAwesomeIcon className='pr-1 text-blue-light dark:text-blue-dark' icon={faPlusCircle} />
                      alle Stories
                    </li>
                    <li>
                      <FontAwesomeIcon className='pr-1 text-blue-light dark:text-blue-dark' icon={faPlusCircle} />
                      alle Podcast
                    </li>
                    <li>
                      <FontAwesomeIcon className='pr-1 text-blue-light dark:text-blue-dark' icon={faPlusCircle} />
                      alle Quizze
                    </li>
                    <li>
                      <FontAwesomeIcon className='pr-1 text-blue-light dark:text-blue-dark' icon={faPlusCircle} />
                      Übersetzungen
                    </li>
                  </ul>
                </div>
                <div
                  className="md:absolute md:bottom-0 md:right-1 md:left-1 flex flex-col">
                  <a
                    href={constants.WEBAPP + 'PremiumScreen?refresh=true'}
                    className="flex flex-row justify-center items-center py-2 px-4 mt-2 mx-3 mb-4 bg-blue-light dark:bg-blue-dark text-white-light dark:text-white-dark text-center text-lg font-bold shadow-md rounded-lg transition duration-320 ease-in-out transform hover:-translate-y-1 hover:scale-105">
                    Preise anzeigen
                    <div className='px-2 animate-pulse'>
                      <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                  </a>
                  <div className='justify-center pb-2 px-4 text-center text-lg text-text-light dark:text-text-dark'>
                    ab 4,99€ pro Monat (inkl. MwSt.), bei jährlicher Abrechnung
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='flex flex-col md:w-1/2 pt-4 mt-1 md:my-0 md:mx-1 bg-grey-light dark:bg-grey-dark rounded-xl shadow-md overflow-hidden'>
            <div className='text-2xl text-center text-pink-light dark:pink-text-dark font-bold'>
              Team-Abonnement
            </div>
            <div className='justify-center pb-2 px-4 text-center text-base text-text-light dark:text-text-dark'>
              Geeignet für mehrere Personen. Premium gilt für Team-Mitglieder.
            </div>
            <div className='flex flex-row justify-around'>
              <div className='w-full items-center justify-center p-1 rounded-md'>
                <div className='flex flex-row px-2'>
                  <div className='flex w-full p-1 px-2 m-1 justify-center text-lg font-semibold text-center text-pink-light dark:text-pink-dark border-2 border-pink-light dark:border-pink-dark rounded-lg'>
                    jährlich
                  </div>
                </div>
                <div className='flex flex-row px-2'>
                  <ul className='list-none m-auto text-lg text-left text-text-light dark:text-text-dark'>
                    <li>
                      <FontAwesomeIcon className='pr-1 text-pink-light dark:text-pink-dark' icon={faPlusCircle} />
                      alle Stories</li>
                    <li>
                      <FontAwesomeIcon className='pr-1 text-pink-light dark:text-pink-dark' icon={faPlusCircle} />
                      alle Podcast</li>
                    <li>
                      <FontAwesomeIcon className='pr-1 text-pink-light dark:text-pink-dark' icon={faPlusCircle} />
                      alle Quizze
                    </li>
                    <li>
                      <FontAwesomeIcon className='pr-1 text-pink-light dark:text-pink-dark' icon={faPlusCircle} />
                      Übersetzungen
                    </li>
                    <li>
                      <FontAwesomeIcon className='pr-1 text-pink-light dark:text-pink-dark' icon={faPlusCircle} />
                      Team-Manager
                    </li>
                    <li>
                      <FontAwesomeIcon className='pr-1 text-pink-light dark:text-pink-dark' icon={faPlusCircle} />
                      Team-Fortschritt
                    </li>
                    <li>
                      <FontAwesomeIcon className='pr-1 text-pink-light dark:text-pink-dark' icon={faPlusCircle} />
                      Umsatzsteuer-Rechnung
                    </li>
                  </ul>
                </div>
                <a
                  href={constants.WEBAPP + 'PremiumScreen?refresh=true'}
                  className="flex flex-row justify-center items-center py-2 px-4 mt-2 mx-3 mb-3 bg-pink-light dark:bg-pink-dark text-white-light dark:text-white-dark text-center text-lg font-bold shadow-md rounded-lg transition duration-320 ease-in-out transform hover:-translate-y-1 hover:scale-105">
                  Preise anzeigen
                  <div className='px-2 animate-pulse'>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                </a>
              </div>
            </div>
            <div className='justify-center pb-2 px-4 text-center text-lg text-text-light dark:text-text-dark'>
              ab 4,20€ pro Monat (zzgl. USt.), bei jährlicher Abrechnung
            </div>
          </div>
        </div>
        <div className='pt-2 md:px-1'>
          <ContactButton />
        </div>
      </div>
    </Layout >
  )
}

export default PricingPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;